.jumbotron {
	margin-bottom: 0;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-size: cover !important;
	
	&.white {
		background: white;
	}
	
	&.green {
		background: $brand-primary;
		color: white;
	}
	
	&.topbar {
		color: white;
		
		@include media-breakpoint-up(xs) {
			background: url('/img/hero/bigbit1_576-min.jpg');
		}
		
		@include media-breakpoint-up(sm) {
			background: url('/img/hero/bigbit1_768-min.jpg');
		}
		
		@include media-breakpoint-up(md) {
			background: url('/img/hero/bigbit1_992-min.jpg');
		}
		
		@include media-breakpoint-up(lg) {
			background: url('/img/hero/bigbit1_1920-min.jpg');
		}
	}
	
	&.video {
		color: white;
		
		@include media-breakpoint-up(xs) {
			background: url('/img/hero/bigbit2_576-min.jpg');
		}
		
		@include media-breakpoint-up(sm) {
			background: url('/img/hero/bigbit2_768-min.jpg');
		}
		
		@include media-breakpoint-up(md) {
			background: url('/img/hero/bigbit2_992-min.jpg');
		}
		
		@include media-breakpoint-up(lg) {
			background: url('/img/hero/bigbit2_1920-min.jpg');
		}
	}
}