.navbar {
	background: white;
	min-height: $navbar-height;
	
	.navbar-brand img {
		max-height: 45px;
	}
	
	.top-menu {
		.nav-item {
			font-size: 18px;
			
			a {
				transition: 300ms color ease;
				
				&.active {
					color: $brand-primary;
    				text-shadow: 0px 0px 20px $brand-primary;
				}
				
				&:hover {
					color: $brand-primary;
				}
			}
		}
	}
	
	.social-networks {
		a {
			transition: 300ms color ease;
			padding: 0.55rem 0.15rem !important;
			font-size: $font-size-sm;
			color: lighten($body-color, 20%) !important;
			
			&:hover {
				color: $brand-primary !important;
			}
		}
	}
}