div.icon {
	background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
	width: 100%;
	
	&.large {
		height: 100px;
	}
	
	&.medium {
		height: 70px;
	}
	
	&.small {
		height: 70px;
		width: 70px !important;
	}
	
	
	&.tech {
		background-image: url('img/icons/tech.svg');
	}
	
	&.tech_green {
		background-image: url('img/icons/tech_green.svg');
	}
	
	&.sound {
		background-image: url('img/icons/sound.svg');
	}
	
	&.star {
		background-image: url('img/icons/star.svg');
	}
	
	&.music {
		background-image: url('img/icons/music.svg');
	}
	
	&.cinema {
		background-image: url('img/icons/cinema.svg');
	}
	
	&.crowd {
		background-image: url('img/icons/crowd.svg');
	}
	
	&.camera {
		background-image: url('img/icons/camera.svg');
	}
	
	&.play {
		background-image: url('img/icons/play.svg');
	}
	
	&.famous {
		background-image: url('img/icons/famous.svg');
	}
	
	&.vip {
		background-image: url('img/icons/vip.svg');
	}
	
	&.light {
		background-image: url('img/icons/light.svg');
	}
}