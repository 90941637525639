.display-3, .display-4 {
	font-weight: 800;
	letter-spacing: -3px;
}

.light {
	font-weight: 300;
}

h5 {
	&.green {
		color: $brand-primary;
	}
}