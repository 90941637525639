body {
	padding-top: $navbar-height;
	
	
	footer {
		font-size: $font-size-sm;
		padding: 1.5rem 0;
		background: $brand-primary;
		color: white;
	}
}